import React, { useRef } from 'react'
import GridContainer from '../../GridContainer'

import navArrow from '../../../images/navArrow2.svg'

import { Icon } from './NavArrow.styles'


const NavArrow = ({ direction = 'next', disabled, onClick, isLastStep, className }) => {

    const navRef = useRef(null)
    return (
        <GridContainer
            isLastStep={isLastStep}
            mobileWidth={isLastStep ? "0px" : ''}
            mobileZindex={isLastStep ? "-1" : ''}
            mobileDisplay={isLastStep ? "none" : ''}
            alignSelf="center"
            // onMouseOver={(e) => {
            //     if (navRef.current)
            //         if (direction === 'next')
            //             navRef.current.style.transform = 'translate(-10px)'
            //         else
            //             navRef.current.style.transform = 'translate(10px)'
            // }}
            // onMouseOut={(e) => {
            //     if (navRef.current)
            //         navRef.current.style.transform = 'translate(0)'
            // }}

            refID={navRef}
            // left="-30px"
            gridTemp={{rows: '1fr', columns: 'min-content min-content'}}
            flexDirection={direction === 'back' ? 'row' : 'row-reverse'}
            opacity={disabled ? '0.25' : '1'}
            gap="5px"
            height="min-content"
            // padding="30px"
            relative
            width="min-content"
            align="center"
            justify="center"
            transition="all 0.3s ease-in-out"
            justifySelf={direction === 'back' ? 'end' : undefined}
            justifyContent={direction === 'back' ? 'start' : undefined}
            // justifyContent={direction === 'next' ? undefined : 'end'}
            gridRow="2" onClick={onClick}>
            <Icon src={navArrow} transform={direction === 'back' ? 'rotate(180deg)' : undefined} />
            <div style={{gridColumn: direction === 'back' ? '2' : '1',gridRow: '1'}}>{direction.toUpperCase()}</div>
        </GridContainer>
    )
}

export default NavArrow