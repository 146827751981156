import React from 'react'
import PageContentContainer from '../PageContentContainer'
import Flexbox from '../Flexbox'
import machineLearningMarketing4 from '../../images/machineLearningMarketing4.svg'
import quickAndEasy from '../../images/quickAndEasy.svg'
import chess from '../../images/summit.svg'
import silverPlatter from '../../images/silverPlatter.svg'
import sparkInterest from '../../images/sparkInterest.svg'
import { RLink } from '../PrivacyPolicy'
import { H2 } from '../Section/Section.styles'
import styled from 'styled-components'

const H2B = styled.h2`
font-size: 2rem;
text-transform: uppercase;
font-weight: bold;
letter-spacing: -.03em;
line-height: 1.2em;
`;
const index = () => {
    const props = {
        flexDirection: 'column',
        lineHeight: "1.3em",
        flexBasis: "50%",
        alignItems: "start",
        flexGrow: '1',
    }
    const props2 = {
        flexWrapMobile: 'wrap'
    }
    return (
        <PageContentContainer
            column
            display="flex"
            // marginTop="0"
            position="relative"
            // background="var(--formBackground90)"
            alignText="start"
            // top="0"
            // color="#fff"
            width="100%"
            height="100%"
            // background="var(--formBackground)"
            justifyContent="start" alignItems="center" flexDirection="row" padding="30px 0 0">
            <Flexbox column padding="0 50px 50px" gap="5px">
                <h1 style={{ margin: '0', fontSize: '2.2rem' }}>About</h1>
                {/* <Flexbox> */}

                <h2 style={{ fontSize: '1.3rem', margin: '0', fontWeight: '300', textAlign: 'center', maxWidth: '700px', color: 'var(--grey1)' }}>
                    We're here to leverage our marketing and machine learning skills to generate real-time leads at a great price.
                </h2>
                {/* <img src={machineLearningMarketing} height="100%" style={{ maxHeight: '400px', width: 'auto' }} /> */}
                {/* <img src={machineLearningMarketing2} height="100%" style={{ maxHeight: '400px', width: 'auto' }} /> */}
                {/* <img src={machineLearningMarketing3} height="100%" style={{ maxHeight: '500px', width: 'auto', margin: '20px 0 0' }} /> */}
                <img src={machineLearningMarketing4} height="100%" style={{ maxHeight: '500px', width: 'auto', margin: '20px 0 0' }} />

            </Flexbox>
            <Flexbox background="#f2f2f2" padding="50px" justifyContent="space-between" gap="50px" {...props2}>
                <Flexbox column lineHeight="1.3em" {...props}>
                    <p> <h1 style={{ lineHeight: '1.2em', textTransform: 'uppercase' }}>Helping Your Firm Reach The Top</h1>
                        Ad clicks for users searching terms like “hire software development company” can cost $20+ each, and the average website takes 40-50 clicks to generate one lead.  That means generating one lead can cost $800-$1,000.
                    </p>
                    <p>
                        Those may be average numbers, but by combining ultra-effective ads with high-conversion landing pages, we generate leads for much lower prices.
                    </p>
                    <p>
                        This allows us to make a profit ourselves, while charging you a per lead cost that is significantly lower than some software development companies achieve with in-house advertising campaigns.
                    </p>
                    <p>
                        We do the hard part for you by creating a custom ad campaign that gets results.  We take on the risk, paying for the clicks, while you only pay for real leads.
                    </p>
                </Flexbox>
                <img src={chess} height="100%" width="auto" style={{ maxHeight: '400px' }} />
                {/* <Flexbox flexBasis="50%">
                </Flexbox> */}
            </Flexbox>
            <Flexbox padding="50px 50px 50px 0" width="100%" justifyContent="space-between" {...props2}>
                <img src={silverPlatter} height="90%" width="auto" style={{ maxHeight: '500px' }} />
                <Flexbox column lineHeight="1.3em"  {...props} margin="0 0 0 50px" >

                    <p><h1 style={{ lineHeight: '1.2em', textTransform: 'uppercase' }}>Exclusive and High Quality</h1>All leads are exclusive.  That means when we generate a lead, unlike other companies, we guarantee it will be sold to you and only you.  We never sell leads to multiple developers.
                    </p>
                    <p>Leads of all types are available, from general categories like mobile and web development, to specialty leads such as machine learning, blockchain, or AR/VR.
                    </p>
                    <p>We use microtargeting to focus on clients who are statistically more likely to be able to afford the large cost of hiring a developer.
                    </p>
                </Flexbox>
            </Flexbox>
            <Flexbox background="#f2f2f2" padding="50px" justifyContent="space-between" gap="50px" {...props2}>
                <Flexbox {...props}>
                    <h1 id="how-onboarding-works" style={{ lineHeight: '1.2em' }}>HOW DOES ONBOARDING WORK?</h1>
                    <p>
                        Fill out our <RLink to="/get-started" target="_blank">“Get Started” form</RLink>, which only takes about 1 minute.  You’ll tell us which lead types you're interested in and a little bit about your development experience.
                    </p>
                    <p>
                        We’ll contact you to answer any questions you have, confirm order details, and send you a checkout link.  You'll typically start receiving leads within 1-2 business days or less, which can be sent by email, text message, and/or CRM posting.
                    </p>
                    <h3>Payment Processing</h3>
                    <p>
                        Make payment at the checkout link to begin receiving leads.  We use Stripe as our payment processor, and you can pay by credit or debit card. <p></p>

                        Once your order is fulfilled, you can re-order in any quantity you'd like.  No commitments.  No auto-billing.
                    </p>
                    <p>

                    </p>
                </Flexbox>
                <img src={quickAndEasy} height="100%" width="auto" style={{ maxHeight: '400px', maxWidth: '35%', minWidth: '200px', margin: '0 auto' }} />
            </Flexbox>
            <Flexbox padding="50px" justifyContent="space-between" gap="50px" width="100%" {...props2}>
                <img src={sparkInterest} height="100%" width="auto" style={{ maxHeight: '400px', maxWidth: '35%', minWidth: '200px', margin: '0 auto' }} />
                <Flexbox column lineHeight="1.3em" flexBasis="50%" {...props}>
                    <h1 style={{ lineHeight: '1.2em' }}>HOW DO YOUR LEAD GENERATION SITES WORK?</h1>
                    <p style={{ maxWidth: '700px' }}>
                        Potential clients search for info about getting their software project developed and land on one of our websites.  We ask for detailed information about their project and priorities.  This allows us to connect them with a development company who matches their needs, while collecting details that may help tailor your pitch. <p></p>Our lead gen sites create a good first impression and spark strong client interest, which we believe increases conversion rates.
                    </p>
                </Flexbox>
            </Flexbox>
        </PageContentContainer>
    )
}

export default index