import React, { useRef } from "react";

import Flexbox from "../Flexbox";
import GridContainer from "../GridContainer";
import GridItem from "../GridItem";
import TextArea from "../TextArea";
import InputField from '../InputField'
import { Icon, Text, SectionImage, Subtext } from "./IconSelection.styles";

const Option = ({
  placeholder = "Subcategories",
  fontSize = "18px",
  subtext,
  textPosition = "bottom",
  id,
  index,
  showIndex,
  selectedBorder,
  notSelectedBorder,
  noBorder = false,
  isFlex,
  text,
  img,
  onClick,
  isSelected,
  stepId,
  imgProps = {},
  tileProps = {},
  mobileImgProps,
  mobileTileProps,
  textAreaValue,
  onTextAreaChange,
  onInputFieldChange,
  showInputFieldOnClick,
  inputFieldValue,
  subtextFontSize,
  subtextFontWeight,
  ...props
}) => {
  const inputRef = useRef()
  return (
    <GridOrFlexWrapper
      position="relative"
      transition="all 0.25s ease-in-out"
      transform={!isSelected ? "scale(0.90)" : ""}
      userSelect="none"
      cursor="pointer"
      // opacity={isSelected ? '1' : '0.35'}
      onClick={function (e) {
        if (!e.target.getAttribute('type') || (e.target.getAttribute('type') !== 'input')) {
          if (!isSelected) onClick();
          else if (!e.target.classList.contains("skipOnClick")) onClick();
        }
      }}
      column
      color={isSelected ? "var(--formSelectedTileColor)" : "var(--formColor)"}
      padding="20px"
      justifyContent="space-around"
      gap="10px"
      borderRadius="10px"
      width={"150px"}
      height={"150px"}
      textAlign="center"
      {...props}
      {...tileProps}
      mobileTileProps={mobileTileProps}
      isFlex={isFlex}
      isSelected={isSelected}
      background={isSelected ? "var(--formSelectedTileBackground)" : undefined}
      border={
        noBorder
          ? undefined
          : isSelected
            ? selectedBorder
              ? selectedBorder
              : "2px solid var(--formSelectedTileColor)"
            : notSelectedBorder
              ? notSelectedBorder
              : "var(--borderMediumThick)"
      }
      isContainer={false}
    >
      {textPosition === "top" && <Text fontSize={fontSize}>{text}</Text>}
      {textPosition === "top" && <Text fontSize={subtextFontSize || fontSize} fontWeight={subtextFontWeight}>{subtext}</Text>}
      {(showInputFieldOnClick && isSelected) &&
        <InputField refId={inputRef} id={id} type="input"
          focusOutline="none"
          padding="0 40px 0 20px"
          onChange={
            function (e) {
              if (typeof onInputFieldChange === "function") {
                onInputFieldChange(stepId, id, e.target.value);
              }
            }}
          outline="none"
          focusBorder="none; border-bottom: 2px solid #fff"
          flexDirection="column"
          background="rgba(0,0,0,0.1)"
          value={inputFieldValue}
          border="none; border-bottom: 1px solid #fff"
          placeholder={placeholder} />}
      {showIndex && index >= 0 && (
        <Flexbox
          position="absolute"
          top="calc(-2em / 2)"
          border="1px solid #fff"
          background="#fff"
          color="var(--formBlue1)"
          boxShadow={"var(--formTileBoxShadow)"}
          height="2em"
          width="2em"
          flexShrink="0"
          fontWeight="600"
          justifyContent="center"
          borderRadius="50%"
          fontSize={fontSize}
        >
          {index + 1}
        </Flexbox>
      )}
      {img && (
        <Icon
          src={img}
          {...imgProps}
          mobileImgProps={mobileImgProps}
          isSelected={isSelected}
        />
      )}
      {textPosition !== "top" && <Text fontSize={fontSize}>{text}</Text>}
      {textAreaValue !== undefined && (
        <Flexbox height="52px" width="100%">
          <TextArea
            rows={1}
            fontSize="12px"
            lineHeight="12px"
            className={"skipOnClick"}
            placeholder={placeholder}
            // disabled={!isSelected}
            disabledBgd="rgba(255,255,255,0.5)"
            width="100%"
            value={textAreaValue}
            // label={_option.text}
            // placeholder={_option.placeholder}
            // onFocus={function () { onChange(_option.id) }}
            onChange={function (e) {
              if (typeof onTextAreaChange === "function") {
                console.log("value is: ", e.target.value);
                onTextAreaChange(e.target.value);
                // onTextAreaChange(id, e.target.value)
              }
            }}
          />
        </Flexbox>
      )}
    </GridOrFlexWrapper>
  );
};

const GridOrFlexWrapper = ({
  children,
  isFlex,
  isContainer = true,
  ...props
}) => {
  if (isFlex)
    return (
      <Flexbox
        // transformHover={!isContainer ? "scale(1.0)" : undefined}
        // transformHoverNotSelected={!isContainer ? "scale(0.95)" : undefined}
        bgdHover={!isContainer ? "var(--formSelectedTileBackground)" : undefined}
        colorHover={!isContainer ? "var(--formSelectedTileColor)" : undefined}
        className="CONTAINERX"
        gap="30px"
        {...props}
      >
        {children}
      </Flexbox>
    );
  if (isContainer)
    return (
      <GridContainer gap="30px" {...props}>
        {children}
      </GridContainer>
    );
  return <GridItem {...props}>{children}</GridItem>;
};
/**
 *
 * @param {*} options
 * @param {string=} gap
 * @param {number=} itemsPerRow
 * @returns string
 */
const getFlexBasis = (options, gap = "10px", itemsPerRow = undefined) => {
  if (itemsPerRow)
    return `calc(${(1 / itemsPerRow) * 100}% - ${gap} * ${itemsPerRow} * 2)`;
  let optionsLength = Object.keys(options).length;
  if (optionsLength <= 4)
    return `calc(${(1 / optionsLength) * 100
      }% - ${gap} * ${optionsLength} * 2)`;
  if (optionsLength % 4 === 1)
    return `calc(${(1 / 3) * 100}% - ${gap} * 3 * 2)`;
  return `calc(${(1 / 4) * 100}% - ${gap} * 4 * 2)`;
};

/**
 *
 * @param {*} options
 * @param {string=} gap
 * @param {number=} itemsPerRow
 * @param {string=} itemHeight
 * @returns Object
 */
const getContainerProps = (
  optionsLength,
  gap = "7.5px",
  itemsPerRow,
  itemsPerRowMobile,
  itemHeight = "150px"
) => {
  if (itemsPerRow && itemsPerRowMobile)
    return {
      height: "100%",
      maxHeight: `calc( ${Math.ceil(
        optionsLength / itemsPerRow
      )} * ${itemHeight} + 2 * ${gap} * ${Math.ceil(
        optionsLength / itemsPerRow
      )})`,
      mobileMaxHeight: `calc( ${Math.ceil(
        optionsLength / itemsPerRowMobile
      )} * ${itemHeight} + 2 * ${gap} * ${Math.ceil(
        optionsLength / itemsPerRowMobile
      )})`,
    };
  else return {};
};

/**
 *
 * @param {*} options
 * @param {string=} gap
 * @param {number=} itemsPerRow
 * @returns Object
 */
const getProps = (
  options,
  gap = "10px",
  itemsPerRow = undefined,
  itemsPerRowMobile = undefined
) => {
  let optionsLength = Object.keys(options).length;
  if (itemsPerRow && itemsPerRowMobile)
    return {
      flexBasis: `calc(${(1 / itemsPerRow) * 100}% - ${gap} * ${itemsPerRow - 1
        } * 2)`,
      mobileFlexBasis: `calc(${(1 / itemsPerRowMobile) * 100}% - ${gap} * ${itemsPerRowMobile - 1
        } * 2)`,
      mobileMaxHeight: `calc(100%/${Math.ceil(
        optionsLength / itemsPerRowMobile
      )} - ${gap} * 
            ${optionsLength > 1
          ? Math.ceil(optionsLength / itemsPerRowMobile)
          : 0
        } * 1)`,
      mobileWidth: `calc(${100 / itemsPerRowMobile}% - ${gap} * ${itemsPerRowMobile > 1 ? itemsPerRowMobile : 0
        } * 2)`,
    };
  if (optionsLength <= 4)
    return {
      flexBasis: `calc(${(1 / optionsLength) * 100
        }% - ${gap} * ${optionsLength} * 2)`,
    };
  if (optionsLength % 4 === 1)
    return {
      flexBasis: `calc(${(1 / 3) * 100}% - ${gap} * 3 * 2)`,
    };
  return {
    flexBasis: `calc(${(1 / 4) * 100}% - ${gap} * 4 * 2)`,
  };
};

const IconSelection = ({
  sectionImage,
  sectionImageProps = {},
  itemsPerRow,
  itemsPerRowMobile,
  itemMargin = "7.5px",
  sortedOptions,
  storeFullOption = false,
  valueArrayKey = "id",
  valueIsSelectedKey = "id",
  showIndex = false,
  outerContainerProps,
  outerContainerWidth,
  mobileImgProps,
  mobileTileProps = {},
  isOptional = false,
  label,
  isFlex = true,
  labelOnTextOnly,
  textPosition,
  options = [],
  multiSelect = false,
  shouldShowOptions = false,
  onChange,
  value,
  containerProps,
  imgProps,
  id: stepId,
  tileProps = {},
  onInputFieldChange,
  placeholder,
  inputFieldValue,
  fontSize,
  subtextLabel,
  subtextStyles,
  hideSubtextLabel = false,
  ...props
}) => {
  const defaultImgProps = {
    height: "70px",
    width: "100%",
    mobileHeight: "40px",
    mobileWidth: "auto",
  };

  const defaultTileProps = {
    minHeightMobile: "100px",
    maxWidth: "200px",
    height: "100%",
    maxHeight: "200px",
    maxHeight: "135px",
    margin: itemMargin || "7.5px",
    noBorder: true,
    flexGrow: "1",
    className: "iconSelectionTile",
    display: "flex",
    flexDirection: "column",
    justify: "center",
    flexBasis: getFlexBasis(options, itemMargin, itemsPerRow),
    width: getFlexBasis(options, itemMargin, itemsPerRow),
    position: "relative",
    selectedBorder: "var(--defaultTileSelectedBorder)",
    boxShadow: "var(--defaultTileBoxShadow)",
    notSelectedBorder: "var(--defaultTileBorder)",
    fontSize: "calc(21px * 1/0.9)",
    mobileMaxWidth: "100%",
    ...getProps(options, itemMargin, itemsPerRow, itemsPerRowMobile),
  };

  const _containerProps = {
    height: "100%",
    width: "100%",
    overflow: "auto",
    maxWidth: "1000px",
    gap: "0",
    flexWrap: "wrap",
    justifyContent: "center",
    ...getContainerProps(
      Object.keys(options).length,
      itemMargin,
      itemsPerRow,
      itemsPerRowMobile,
      tileProps["maxHeight"] || defaultTileProps["maxHeight"]
    ),
  };

  const valueIsSelected = (_option) => {
    return !multiSelect
      ? _option[valueIsSelectedKey] === (storeFullOption ? value[valueIsSelectedKey] : value)
      : storeFullOption
        ? value.findIndex(
          (el) => el[valueIsSelectedKey] === _option[valueIsSelectedKey]
        ) >= 0
        : value.includes(_option[valueIsSelectedKey]);
  };

  return (
    <Flexbox
      flexDirection="column"
      // flexWrap="wrap"
      overflow="auto"
      width={outerContainerWidth}
      alignContent="center"
      justifyContent="center"
      justifyMobile="start"
      height="100%"
      position="relative"
      {...outerContainerProps}
    >
      {sectionImage && (
        <SectionImage src={sectionImage} {...sectionImageProps} />
      )}
      <h3>
        {!multiSelect && options[value] && options[value].label !== undefined
          ? options[value].label
          : label}
      </h3>
      {
        ((subtextLabel !== undefined || multiSelect) && !hideSubtextLabel) &&
        < Subtext >
          {subtextLabel || "Select all that apply, then press next."}
        </Subtext>
      }

      <GridOrFlexWrapper
        isFlex={isFlex}
        {..._containerProps}
        {...containerProps}
      >
        {(Array.isArray(sortedOptions)
          ? sortedOptions
          : Object.keys(options)
        ).map((option, index) => {
          // console.log("option: ", option)
          const _option = options[option];
          if (_option.type && _option.type === "textAreaInput") {
            return (
              <GridOrFlexWrapper
                isFlex={isFlex}
                isContainer={false}
                {...defaultTileProps}
                {...tileProps}
                {...options.tileProps}
                {..._option.tileProps}
              >
                <Flexbox column flexBasis="100%">
                  <TextArea
                    width="100%"
                    value={_option.value}
                    label={_option.text}
                    placeholder={_option.placeholder}
                    // onFocus={function () { onChange(_option.id) }}
                    onChange={function (e) {
                      const value = e.target.value;
                      onChange(_option.id, value);
                      if (typeof _option["onChange"] === "function")
                        _option.onChange(value);
                    }}
                  />
                </Flexbox>
              </GridOrFlexWrapper>
            );
          }
          return (
            <Option
              showIndex={showIndex}
              textPosition={textPosition}
              onInputFieldChange={onInputFieldChange}
              inputFieldValue={inputFieldValue}
              placeholder={placeholder}
              stepId={stepId}
              index={
                multiSelect
                  ? value.findIndex((v) => v === _option[valueArrayKey])
                  : index
              }
              isFlex={isFlex}
              isSelected={valueIsSelected(_option)}
              key={_option.id}
              onClick={function () {
                if (valueIsSelected(_option) && isOptional && !multiSelect)
                  onChange("no-answer", value);
                else onChange(storeFullOption ? _option : _option[valueArrayKey], value, null, _option);
              }}
              fontSize={fontSize}
              {...defaultTileProps}
              {...tileProps}
              {...options.tileProps}
              {..._option}
              imgProps={{
                ...defaultImgProps,
                ...imgProps,
                ..._option["imgProps"],
              }}
              {...mobileTileProps}
              {...mobileImgProps}
            />
          );
        })}
      </GridOrFlexWrapper>
    </Flexbox >
  );
};

export default IconSelection;
