class IconSelection {
    constructor(name, _enum, labelInternal = false, outerContainerProps = null) {
        this.name = name
        this.enum = _enum
        this.outerContainerProps = outerContainerProps || {
            justifyContent: 'center',
            justifyMobile: 'start'
        }
        this.gridTempAreas = labelInternal ? "'back content next'" : "'back label next''back content next'"
        if (Object.keys(this.enum).length < 4){

        }
    }
}
class IconSelectionOption {
    /**
     * 
     * @param {string} displayName 
     * @param {*} img 
     * @param {*} id 
     * @param {*} text 
     * @param {*} imgProps 
     * @param {*} subcategories 
     */
    constructor(displayName, img = null, id = null, text = null, imgProps, subcategories) {
        this.id = id || displayName.replace(/ /g, "-").toLowerCase()
        this.img = img
        this.text = text || displayName
        this.displayName = displayName
        this.subcategories = subcategories
        this.imgProps = imgProps
    }
}

const fixSvgElementCoordinates = (className, xFactor = 2, yFactor = 2, xNb = null, yNb = null) => {
    let elements;
    elements = document.querySelectorAll(className);
    let updatedBBoxArray = []
    Array.from(elements).forEach((element) => {
        let BBox = element.getBBox();
        let BBoxX2 = xFactor == 0 ? 0 : xNb ? xNb : BBox["width"] / xFactor;
        let BBoxY2 = yFactor == 0 ? 0 : yNb ? yNb : BBox["height"] / yFactor;
        let BBoxX = xNb ? xNb : BBox["x"] + BBoxX2;
        let BBoxY = yNb ? yNb : BBox["y"] + BBoxY2;
        BBox = BBoxX + "px" + " " + BBoxY + "px";
        setSvgElementCoords(element, BBox)
        // element.style.transformOrigin = BBox;
        updatedBBoxArray.push(BBox)
    });
    return updatedBBoxArray
}

const setSvgElementCoords = (element, bboxString) => {
    element.style.transformOrigin = bboxString;
}

class GridTemp {
    constructor(r, c) {
        this.rows = r
        this.columns = c
    }
}

export const formatPrice = (_value) => {
    const MAX_DEC_PLACES = 2
    let value = _value.toString()
    let price = value.split('.')[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let decimal; //MAX_DEC_PLACES
    if (value.split('.').length > 1) {
        decimal = (value.split('.')[1] + '0').substring(0, MAX_DEC_PLACES).replace(/\D/g, "")
        price = price + '.' + decimal;
    }
    return '$' + price
}

const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}
const formatPhoneNumber = (phoneNumber) => {
    let number = phoneNumber.slice(0, 10).split('').reduce((a, b, index) => {
        if ((index) % 3 === 0 && index < 7 && index > 0)
            a = a + "-" + b
        else
            a = a + b
        return a
    }, '')

    return number
}

const debounce = (callback, wait) => {
    console.log("debouncing", wait)
    let timeout;
    return function (...args) {
        console.log("did debounce", timeout)
        clearTimeout(timeout)
        timeout = setTimeout(async () => {
            console.log("exec::::::")
            await callback(...args)
        }, wait)
    }

}

export { IconSelectionOption, GridTemp, fixSvgElementCoordinates, toTitleCase, formatPhoneNumber, debounce, setSvgElementCoords }