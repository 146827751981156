import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom'

const DescriptionAndKeywords = ({ pathname }) => {
    if (pathname.includes('web-development'))
        return (
            <Helmet prioritizeSeoTags>
                <title>Buy Web Development Leads | Software Dev Leads</title>
                {/* <link rel="notImportant" href="https://www.chipotle.com" />
            <meta name="whatever" value="notImportant" />
            <link rel="canonical" href="https://www.tacobell.com" />
            <meta property="og:title" content="A very important title" /> */}
                <meta
                    name="description"
                    content="Lead gen for software development companies. Exclusive web dev and mobile app leads generated in real-time. Blockchain, AI, machine learning, AR, VR, IoT."
                />
                <meta
                    name="keywords"
                    content="Lead Generation, Software Development Company, Lead Gen, Web Development, pay per lead, exclusive leads, Web Development Company, Mobile Development Company, App Development Company, Web Development Service, Mobile Development Service, App Development Service, Buy Leads, Exclusive Leads, Real-Time Leads, blockchain, ai, ml, artificial intelligence, machine learning, ar, vr, IoT, augmented reality, virtual reality, leads, leadgen" />
            </Helmet>
        )
    else
        return (
            <Helmet prioritizeSeoTags>
                
                <title>Software Dev Leads | Lead Gen for Web and Mobile Dev Company</title>
                <meta
                    name="description"
                    content="Lead gen for software development companies. Exclusive web dev and mobile app leads generated in real-time. Blockchain, AI, machine learning, AR, VR, IoT."
                />
                <meta
                    name="keywords"
                    content="Lead Generation, Software Development Company, Lead Gen, Web Development, pay per lead, exclusive leads, Web Development Company, Mobile Development Company, App Development Company, Web Development Service, Mobile Development Service, App Development Service, Buy Leads, Exclusive Leads, Real-Time Leads, blockchain, ai, ml, artificial intelligence, machine learning, ar, vr, IoT, augmented reality, virtual reality, leads, leadgen" />
            </Helmet>
        )

}

const DynamicMetaData = () => {
    const { pathname } = useLocation()
    return (
        <DescriptionAndKeywords pathname={pathname} />
    )
}

export default DynamicMetaData