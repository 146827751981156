import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// CSS
import { GlobalStyle } from './GlobalStyle';

// Components

import Checkout from './components/Checkout'

import FAQ from './components/FAQ'
import Navigation from './components/Navigation'
import Form from './components/Form'
import Pricing from './components/Pricing';
import Error from './components/Error'
import Success from './components/Success'
import ContactForm from './components/ContactForm'
import Footer from './components/Footer'
import ScrollToTop from "./components/scrollToTop";
import PrivacyPolicy from './components/PrivacyPolicy';
import DNSMI from './components/DNSMI';
import TermsAndConditions from './components/TermsAndConditions';
import About from './components/About'
import PaymentSuccessful from './components/PaymentSuccessful'
import RefundPolicy from './components/RefundPolicy';

// All your home component imports should go here
// import {NAME OF FILE} from './components/{NAME OF FILE}'
import Home from './components/Home'
import HomeWebDev from './components/HomeWebDev'
import HomeBlockchain from './components/HomeBlockchain'

import { Helmet, HelmetProvider } from 'react-helmet-async';
import DynamicMetaData from './components/DynamicMetaData'

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Router>
          <DynamicMetaData />
          <ScrollToTop />
          <Navigation />
          <Routes>
            <Route path="/" exact element={< Home />} />
            {/* <Route path="/web-development" exact element={< HomeWebDev />} /> */}
            {/* <Route path="/blockchain-development" exact element={< HomeBlockchain />} /> */}
            {/* <Route path="/web-development" exact element={< HomeWebDev/>} /> */}
            <Route path="/faq" exact element={< FAQ />} />
            <Route path="/success" exact element={< Success />} />
            <Route path="/pricing" exact element={< Pricing />} />
            <Route path="/contact" exact element={< ContactForm />} />
            <Route path="/get-started" exact element={< Form />} />
            <Route path="/form" exact element={< Form />} />
            <Route path="/privacy-policy" exact element={< PrivacyPolicy />} />
            <Route path="/refund-policy" exact element={< RefundPolicy />} />
            <Route path="/do-not-sell-my-info" exact element={< DNSMI />} />
            <Route path="/terms" exact element={< TermsAndConditions />} />
            <Route path="/checkout/:userId" exact element={< Checkout />} />
            <Route path="/payment-successful" exact element={< PaymentSuccessful />} />
            <Route path="/about" exact element={< About />} />
            <Route path="/error" exact element={< Error />} />
          </Routes>
          <Footer />

          <GlobalStyle />
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
