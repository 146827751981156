import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import PageContentContainer from './PageContentContainer'
import Section from './Section'

const Container = styled.div`
/* box-shadow: var(--formTileBoxShadow); */
display: grid;
position: relative;
width: 95%;
max-width: 700px;
padding: 20px 20px 40px;
grid-auto-flow: row;
grid-template-columns: 1fr;
grid-auto-rows: min-content;
gap: 15px;
height: 100%;
min-height: calc(100vh - var(--navBarHeight));
`;

const Label = styled.label`
font-size: 14px;
text-align: left;

h3{
    margin: 0 0 5px;
}
`;

export const Link = styled.a`
color: var(--formBlue1);
display: inline;
`;

const GridC = styled.div`
display: grid;
grid-template-rows: min-content;
grid-template-columns: ${({ cols = 2, gridCol }) => gridCol || `repeat(${cols}, 1fr)`};
gap: 15px;
@media only screen and (max-width: 550px), screen and (max-device-width: 550px) {
    grid-template-columns: 1fr;
    /* gap: 0; */
    grid-template-rows: min-content;
}
`;

export const H3 = styled.h3`
margin: 0.35em 0 0.2em;
text-align: left;
font-size: 1rem;
`;

export const P = styled.p`
margin: 0;
text-align: left;
/* color: #4b5d63; */
`;

const ContainerContent = styled.div`
background: white;
padding: 2rem;
width: 100%;
max-width: 800px;
margin-bottom: 2rem;
`;

const RefundPolicy = () => {
    return (
        <PageContentContainer
            minHeight="fit-content"
            // margin="calc(var(--navBarHeight) * -1) 0 0"
            height="100%"
            padding="0"
            color="#000"
            scrollBehavior="smooth"
            // scrollSnapType="y mandatory"
            background="#e5f0ef"
            // background="#000"
            transition="0.2s all  linear"
            column
        >
            <ContainerContent>
                <H3>Refund & Cancellation Policy</H3>
                <P>We are committed to providing our clients with exceptional service. However, it is important to note that once a payment is made for any of our services, it is non-refundable. This includes, but is not limited to, any initial setup fees, ongoing service fees, project fees, or any other charges associated with the services provided by our company.</P>
                <H3>Cancellation Policy</H3>
                <P>If an order or service engagement is canceled at any point by the client for any reason, please be aware that there will be NO REFUNDS of any amounts already paid. Cancellation of services may result in the forfeiture of any payments made. This policy is in place to cover the costs incurred by our company in the initial stages of project planning, resource allocation, and other preparatory actions taken to deliver our services.</P>
                <H3>Discretionary Considerations</H3>
                <P>While our policy is firm, we understand that every situation is unique. In exceptional circumstances, the decision to provide a refund or partial refund will be at our sole discretion. However, clients should assume that the standard policy of no refunds will apply in most situations.</P>
                <H3>Acknowledgment</H3>
                <P>By engaging our services and making payment, clients acknowledge and agree to this NO-REFUND policy. We advise our clients to carefully consider their needs and the terms of this policy before proceeding with any service or payment.</P>
                <H3>Chargebacks</H3>
                <P>If we receive a chargeback or payment dispute (i.e. PayPal Dispute) from a credit card company or bank, your service and/or project will be suspended without notice. A $50 chargeback fee (issued to recover fees passed on to us by the credit company), plus any outstanding balances accrued as a result of the chargeback(s) must be paid in full before service is restored, files delivered, or any further work is done. Instead of issuing a chargeback, please contact us to address any billing issues. Requesting a chargeback or opening a PayPal dispute for a valid charge from us is fraud, and is never an appropriate or legal means of obtaining a refund. Please read and make sure you fully understand our refund policy prior to making a payment.</P>
                <H3>Cancellation Notification</H3>
                <P>All cancellations must be made in writing or via email to <Link href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>, and require a 30-day notice. Cancellation requests will only be processed if made by the initial authorizing party and if received in writing or via email. Once a cancellation request is made, it is the initial authorizing party's responsibility to ensure that <span className='companyName'></span> is in receipt of the cancellation notification.</P> <P className='text-left text-slate-500 md:text-sm font-normal' ><span className='companyName'></span> reserves the right to disable and/or terminate a user's account if a user is found in violation of the terms. Accounts terminated due to policy violations will not be refunded.</P>
            </ContainerContent>
        </PageContentContainer>
    )
}

export default RefundPolicy