import React from 'react'

import PageContentContainer from './PageContentContainer'
import { useNavigate } from 'react-router-dom'

import Section from './Section'
import ButtonHome from './ButtonHome'
import Flexbox from './Flexbox'
import GridContainer from './GridContainer'
import GridItem from './GridItem'
import Rocket from './Rocket'
import FAQ from './FAQ'
import HowItWorks from './HowItWorks'
import Building from './Building'
import styled from 'styled-components'

import MatchIconAnimated from './MatchIconAnimated'
import magnify from '../images/magnify.svg'
import phone from '../images/phone.svg'
import matchIcon from '../images/matchIcon.svg'

import stopwatch from '../images/stopwatch.svg'
import chart from '../images/growth.svg'
import diamond from '../images/diamond.svg'
import solicitor from '../images/noSolicitors.svg'
import screenedDevelopers from '../images/screenedDevelopers.svg'
import { RLink } from './PrivacyPolicy'
import Footer from './Footer'
import PageList from './PageListStyle2'

const H1 = styled.h1`
font-size: 3rem;
margin: 0;
@media only screen and (max-width: 550px),
    screen and (max-device-width: 550px) {
        font-size: 1.6rem;
        text-align: center;
margin: 0;
    }
`;

const H3 = styled.h3`
margin: 0;
font-weight: 400;
@media only screen and (max-width: 550px){
        text-align: center;
        font-size: 1.3rem;
    }
`

const dedicatedPageContent = (page) => {
    switch (page) {
        case 'healthcare':
            return ({ mainHeading: 'Get healthcare leads', subHeading: <>Beep Beep</> })
        case 'blockchain':
            return ({ mainHeading: 'Get blockchain leads', subHeading: <>Beep blockchain</> })

        default:
            return ({
                mainHeading: <>Connect with clients.<br />Get hired. Increase revenue.</>,
                subHeading: <>Real-time leads from targeted search engine ads.<br />Get more clients and take your company to the stars.</>
            })
    }
}

const Home = ({ page = 'default' }) => {
    console.log("the value of page is: ", page)
    const { mainHeading, subHeading } = dedicatedPageContent(page)

    const navigate = useNavigate()
    const listContent = [
        { heading: 'Clients search for developer info, find our ads and enter project details.', img: magnify },
        { heading: 'Client information is sent to you via email, text and/or CRM posting.', img: matchIcon },
        { heading: 'Contact your potential client and provide a consultation about your services.', img: phone },
    ]
    const content = {
        title: "How It Works",
        id: 'how-it-works',
        props: {
            color: '#fff',
            // background: 'var(--formBackgroundAngled)',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'center'
        },
        justifyContent: 'center',
        flexWrap: 'wrap',
        listItemMargin: 30,
        flexGrow: 0,
        headingFontSize: '1.0rem',
        maxWidth: "1200px",
        subTextStyling: {
            color: '#fff',
            // fontSize: '14px'
        },
        subtext:
            [

                {
                    text:
                        <>
                            <h3 style={{ margin: '0' }}>We only advertise on search engines</h3>
                            <p className="marginOnMobile" style={{ margin: '.5em 0 0' }}>  Highly-effective paid search engine ads drive traffic to our software development lead generation sites.</p>
                            <p className="marginOnMobile" style={{ margin: '0 0 0.5em' }}>Each client fills out a form providing details about their project and goals.</p>
                            <h3 style={{ margin: '0' }}>No low-quality social media leads</h3>
                            <p className="marginOnMobile" style={{ margin: '.5em 0 0' }}>All leads are actively seeking to develop and launch a project, and provide details that help tailor your pitch.</p>
                            <p className="marginOnMobile" style={{ margin: '0 0 0.5em' }}>Leads are exclusive, fresh, motivated, and guaranteed to be valid.</p>

                            <p className="marginOnMobile" style={{ margin: '0 0 0.5em' }}>Learn more <RLink to="/about">here</RLink>.</p>
                        </>,
                    position: 'start',
                },

            ],
        listContent,
        button: {
            text: "Get Started",
            boxShadow: "rgba(0, 0, 0, 0.5) 0px 0px 7px",
            background: "var(--formBackgroundAngled)",
            bgdHover: "var(--formBackgroundAngled2)",
            border: "none",
            borderHover: 'none',
            // colorHover: '#fff',
            onClick: () => navigate('/get-started')
        }
    }

    const whyChooseUs = {
        title: 'Get the Reward, Not the Risk',
        props: {
            // background: 'var(--formBackgroundAngled)',
            color: 'var(--formColor)',
            wrap: 'wrap', gap: '20px',
            justifyContent: 'center',

        },
        listParagraphColor: "#fff",
        listItemMargin: 30,
        flexWrap: 'wrap',
        maxWidth: '800px',
        listClassName: 'noWrapOnMobile',
        listContentTextProps: { lineHeight: '1.2em' },
        listContent: [
            { heading: 'Lower cost per client', background: "var(--defaultButtonBackgroundHover)", text: 'We leverage our skills in search engine marketing and AI tools to produce high-quality leads at a price that may be lower than in-house marketing.' },
            { heading: 'Start small with no risk', text: "Start with a tiny order to test us out.  No need to commit to 100 or even 10 leads. No contracts or auto-billing. Confirm it's profitable, then scale up. The sky's the limit.", },
            { heading: 'Highly motivated to hire', text: "Search engine leads are considered the best because they're the most motivated. Less wasted time. Higher closing rate." },
            { heading: 'Substantial profit potential', text: "Given average pricing of development projects and hiring rates for search engine leads, highly significant ROI is possible. " },
        ],
    }

    const growYourBusiness = {
        title: 'Grow Your Business',
        itemProps: {
            flexGrowMobile: '0', flexBasisMobile: 'auto',
            // maxWidth: '200px'
        },
        flexWrap: 'wrap',
        justifyContent: 'center',
        listParagraphColor: "#fff",
        listContainerProps: { maxWidth: '1000px', gap: '10px 40px' },
        listContentTextProps: { lineHeight: '1.2em' },
        // maxWidth: 'calc(200px * 2 + 20px * 2 * 2)',
        props: { wrap: 'wrap', gap: '20px', color: '#fff' },
        subTextStyling: {
            color: '#fff',

        },
        subtext:
            [
                {
                    text:
                        <>
                            Imagine helping more clients realize their vision, while significantly increasing your revenue. We help achieve this by generating quality leads that are more likely to become paying clients.<br /><br /> Leads are matched with developers based on your company's strengths being the best match for their goals, so they're eager to hear from you.
                            <br /><br />
                            We meet any budget requirements.  Start small and scale up as your business grows.
                        </>,
                    position: 'start'
                },],
        listContent: [
            {
                background: "var(--defaultButtonBackgroundHover)",
                heading: 'Real-time Leads',
                text:
                    'Receive leads immediately after client fills out our form. They are actively looking to speak with you and discuss hiring details.',
                img: stopwatch,
                // textColor: 'var(--grey1)'
            },
            {
                heading: 'High Quality',
                text: 'Search ads driven by microtargeting equals more conversions and less wasted time. Each lead is sent to you and no one else.',
                img: diamond,
                // textColor: 'var(--grey1)'
            },
            {
                heading: 'Scale Up', text:
                    'Scale up your company.  We are able to provide significant and scalable lead volume that grows with your business.   ', img: chart,
                // textColor: 'var(--grey1)'
            },
            {
                heading: 'No Hassles', text: 'No sales call required to get started.  No upselling or overcharging. No annoying lead management software.', img: solicitor,
                // textColor: 'var(--grey1)'
            },
        ],
    }

    return (
        <PageContentContainer
            column
            display="flex"

            margin="calc(var(--navBarHeight) * -1) 0 0"
            position="relative"
            // background="var(--formBackground90)"

            // background="linear-gradient(#000,var(--formBlue2))"
            background="linear-gradient(var(--formBlue1),var(--formBlue2))"
            // bgdSize="100% 100vh"
            // backgroundAttachment="fixed"
            // background="linear-gradient(90deg,var(--formBlue1), var(--formBlue2))"
            // top="0"
            // color="#fff"
            width="100%"
            height="100%"
            // background="var(--formBackground)"
            justifyContent="start" alignItems="start" flexDirection="row" padding="0 0 ">
            <GridContainer
                // background="var(--formBackground)" 
                // background="linear-gradient(-45deg, #fff, rgba(27, 134, 246, 1))"
                // color="#fff"
                width="100%"
                gridTempMobile={{ columns: '1fr', rows: '' }}
                gridAutoRowMobile="min-content"
                gridTemp={{ rows: 'repeat(1, min-content)', columns: '2fr 1fr' }}
                gap="20px"
                color="#fff"
                padding="calc(var(--navBarHeight) * 2) 50px 50px"
                paddingMobile="calc(var(--navBarHeight) + 5px) 5% 50px"
                background="linear-gradient(var(--darkBlue),var(--deepBlue))"
                style={{ textAlign: 'left' }}>
                {/* <h1 style={{margin: '0'}}>Couldn't find your freelancelot?</h1> */}
                <Flexbox column alignItems="start" justifyContent="center" alignMobile="center">
                    <H1>{mainHeading}</H1>
                    {/* <h1>Connect with clients looking to hire a software development company.</h1> */}
                    <GridContainer gap="0.1em" margin="1em 0" mobileGap="1em">
                        {/* <h3 style={{ margin: '1em 0 !important' }}>{subHeading}
                        </h3> */}
                        <H3 >Real-time leads from targeted search engine ads.</H3>
                        <H3 >Get more clients and take your company to the stars.</H3>
                    </GridContainer>
                    <ButtonHome
                        width="90%"
                        mobileWidth="100%"
                        maxWidth="250px"
                        mobileMax="100%"
                        // border="none" borderHover="none" colorHover="#fff" 
                        text="Get Started"
                        boxShadow="rgba(0, 0, 0, 0.5) 0px 0px 7px"
                        onClick={() => navigate('/get-started')} />
                </Flexbox >
                <GridItem row="2" col="1">
                    {/* <p style={{margin: '0'}}>
                    Did your freelance developer, knight-in-shining-armor, turn out to be more of a Don Quixote?
                    <br/>
                    <br/>
                    Hiring a freelance developer is time consuming.
                    <br/>
                    <br/>
                    Get matched with a real development firm. Save time, get your project launched faster.
                    </p> */}
                </GridItem>
                <GridItem row="1 / span 1" alignSelf="center" col="2" mobileCol="1">
                    <Rocket />
                </GridItem>
            </GridContainer>
            <Flexbox
                column
                width="100%"
                background="linear-gradient(var(--deepBlue),var(--formBlue1))"
                position="relative"
            >
                <Section {...content} itemsPerRow={3} />
                <Building />
                <PageList />
                <Section {...whyChooseUs} itemsPerRow={2} />
                <FAQ isHome={true} />
            </Flexbox>
        </PageContentContainer>
    )
}

export default Home