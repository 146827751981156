import axios from "axios";

const api = {
    getBudget: async (uId) => {
        let res = await axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_DOMAIN}/users/getBudget?id=${uId}`
        });
        return res
    },
    checkout: async (data) => {
        let res = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_DOMAIN}/users/checkout`,
            data: data
        });
        return res['data']
        
    },
    submitForm: async (_data) => {
        // try {
        console.log("_data", _data)
        var data = JSON.stringify({
            ..._data
        });
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/form/submit/createClient`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        });

        return res
        // } catch (error) {
        //     const { response: { status, data: { msg } } } = error
        //     return { status, msg }
        // }

    },
    submitContactForm: async (_data) => {
        // try {
        console.log("_data", _data)
        var data = JSON.stringify({
            ..._data
        });
        let res = await axios({
            method: 'post', // & controllers
            url: `${process.env.REACT_APP_API_DOMAIN}/contact`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        });

        return res
        // } catch (error) {
        //     const { response: { status, data: { msg } } } = error
        //     return { status, msg }
        // }

    },
}

export default api;