import React, { useState, useEffect, useRef } from 'react'

import Flexbox from '../Flexbox'
import FadeIn from '../FadeIn'
import Icon from '../Icon'
// import Footer from '../Footer'

import addIcon from '../../images/addIcon.svg'

import anime from 'animejs'

const Collapsible = ({ qa, index }) => {
    const [open, setOpen] = useState(false)
    const elementRef = useRef(null)
    const collapseSection = (element) => {
        if (open)
            anime({
                targets: `.qa${index}`,
                height: [0, '100%'], // from 100 to 250
                delay: 500,
                // direction: 'alternate',
                loop: false
            });
        else
            anime({
                targets: `.qa${index}`,
                height: [0, '100%'], // from 100 to 250
                delay: 500,
                // direction: 'alternate',
                loop: false
            });
        // var sectionHeight = element.scrollHeight;
        // if (!open)
        //     requestAnimationFrame(function () {
        //         element.style.height = 0 + 'px';
        //     });
        // if (open)
        //     requestAnimationFrame(function () {
        //         element.style.height = sectionHeight + 'px';
        //         element.style.transition = "";

        //         // on the next frame (as soon as the previous style change has taken effect),
        //         // have the element transition to height: 0

        //     });
    }
    // useEffect(() => {
    //     if (elementRef.current) {
    //         console.log("changing height")
    //         var sectionHeight = elementRef.current.scrollHeight;
    //         if (open)
    //             elementRef.current.height = sectionHeight + 'px';
    //         else
    //             elementRef.current.height = '0px';
    //     }
    // }, [open])
    return (
        // <FadeIn padding="20px" onClick={() => { setOpen(!open) }}
        //     column alignItems="start" flexGrow="1" flexBasis="100%" justifyContent="start">
        <Flexbox
            padding="20px 0 0" onClick={() => {
                setOpen(!open)
            }}
            column alignItems="start" flexGrow="1" flexBasis="100%" justifyContent="start">
            <Flexbox>
                <h3 style={{ margin: '0', fontWeight: '500', textAlign: 'left' }} >{qa['question']}</h3>
                <Icon src={addIcon} transition="all 0.25s ease-in" height="25px" width="25px" transform={open ? "rotate(45deg)" : "rotate(0deg)"} />
            </Flexbox>

            <Flexbox
                className={`qa${index}`}
                refID={elementRef}
                column
                transition="all 1s ease-in"
                alignItems={"start"}
                // flex={open ? '1' : '0'}
                transitionDelay="0"
                height={"auto"}
                maxHeight={open ? "1800px" : "0px"}
                // height="auto"
                overflow={"hidden"}>
                {typeof (qa['answer']) === 'string' && qa['answer'].split('\\n').map((el, index) =>
                    <p key={index}
                        style={{
                            // color: 'var(--grey1)',
                            color: '#fff',
                            fontSize: '1.0rem',
                            textAlign: 'left'
                            // textAlign: 'center'
                        }}>
                        {el}
                    </p>
                )}
                {
                    typeof (qa['answer']) !== 'string' &&
                    <p
                        style={{
                            // color: 'var(--grey1)',
                            color: '#fff',
                            fontSize: '1.0rem',
                            textAlign: 'left'
                            // textAlign: 'center'
                        }}>
                        {qa['answer']}
                    </p>
                }

            </Flexbox>
        </Flexbox>
    )
}

const FAQ = ({ isHome = false }) => {
    const [faq, setFAQ] = useState([])
    useEffect(() => {
        const fetchFAQ = async () => {
            // let _faq = await API.getFAQ()
            let _faq = [
                {
                    question: 'How profitable are your leads?',
                    answer: <>
                        We can't promise a specific closing rate and the way your company prices projects will vary, but profitability can be significant based on average pricing and the closing percentages commonly seen with search engine generated leads.
                        
                        <br />
                        <br />
                       For example, if you order 10 leads at $300 each and get hired by 1 of 10, you brought in a project for $3,000.  Software projects often price at $20-$60k+, so we believe our leads have great potential.
                        
                    </>,

                },
                {
                    question: 'When can I expect leads?',
                    answer: <>
                        You should start receiving leads within 1-2 business days, or sooner.
                    </>,
                },
                {
                    question: 'What determines how many leads I receive per week/month?',
                    answer:
                        <>
                           You buy a set number of leads, so you only receive the amount of leads you have selected to receive.  There is no contract and no auto-billing, so we will never charge you beyond what you have expressly ordered.
                           <br /><br />
                           Lead volume depends on various factors, including which development areas you select.  For example, you can receive far more leads if you select all web development and mobile app leads, than if you select something more specialized.       <br /><br />
                           
                           We can typically generate multiple leads per day for most clients, but can also spread out your budget over a longer period of time if requested.
                            
                           
                        </>,
                },
                {
                    question: 'How do you deliver the leads?',
                    answer:
                        <>
                            We send leads via email but can accomodate requests to send via SMS text or other methods. We can also integrate lead delivery with your CRM.
                        </>,
                },
                {
                    question: 'What does it mean that all leads are guaranteed valid?',
                    answer:
                        <>
                            If a lead is invalid, we will replace it at no charge.  Invalid typically means the client has entered incorrect contact info, or may apply to other rare cases such as a lead saying they never filled out a form requesting development info, etc.
                        </>,
                        },
                        {
                            question: 'Any hidden fees or hassles?',
                            answer:
                                <>
                                    No hidden fees whatsoever. You pay per lead and lead pricing is always agreed upon before you pay. There are no subscriptions, memberships, premium accounts, cancellation fees, or any other kind of fees. We avoid auto-billing to ensure you are never charged unless you place an order. <br /><br /> The only restriction is we have to protect our leads by confirming you have a past history of success, which you can do by providing links to your portfolio, good reviews, customer testimonials, etc., so we can be certain we're matching each lead with a skilled development company.
                                </>,
                },
                {
                    question: 'Minimum order size?',
                    answer:
                        <>
                            To make getting started as easy as possible, there is no minimum order size.  However, we strongly recommend you try at least 10 leads or your sample size may be too small to judge lead quality.
                        </>,
                },
                {
                    question: 'Contracts?',
                    answer:
                        <>
                            Never. Stop or pause any time for any reason.
                        </>,
                        
                },
                
            ]
            // _faq = _faq.map(item => {
            //     // let answer = item['answer'].split('\\n')
            //     // answer = answer.map((el, index) =>

            //     //     <p key={index} style={{
            //     //         color: 'var(--secondary)', fontSize: '1.2rem',
            //     //         // textAlign: 'center'
            //     //     }}>
            //     //         {el}
            //     //     </p>

            //     // )
            //     return ({
            //         question: item['question'],
            //         answer: item['answer'],
            //         // answer

            //     })
            // })
            setFAQ(_faq)
        }
        fetchFAQ()
    }, [])
    return (
        <Flexbox column position="relative"
            width="100%"
            margin={!isHome ? 'calc(var(--navBarHeight) * -1 ) 0 0' : undefined}
            background={!isHome ? "var(--formBackgroundAngled)" : undefined}
            minHeight={!isHome ? "calc(100vh - var(--navBarHeight))" : ''} alignItems="center">
            <Flexbox 
            padding={!isHome ? 'calc(var(--navBarHeight) * 1) 50px 50px' : '0 50px 50px'}
            
                mobilePadding="50px 5%"
                height="100%"
                width="100%"
                // minHeight={!isHome ? "calc(100vh - var(--navBarHeight))" : ''}
                position="relative"
                // overflow={!isHome ? "hidden auto" : undefined} 
                column alignItems="center"
            
            color="#fff" 
            >
                <h1>F.A.Q.</h1>
                {/* <p>lorem</p> */}
                <Flexbox gap="0px" flexWrap="wrap" height="min-content" alignItems="start" maxWidth="1000px">

                    {
                        faq.map((it, index) => {
                            return (
                                <Collapsible qa={it} key={index} index={index} />
                            )
                        })
                    }

                </Flexbox>

            </Flexbox>
            {/* {
                !isHome && <Footer />
            } */}
        </Flexbox>
    )
}

export default FAQ